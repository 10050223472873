
import 
  React, 
  { Component } 
from 'react';
import moment 
  from "moment";
import 
  DateRangePicker 
from 'react-bootstrap-daterangepicker';

// css
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// custom
import { 
  IsEmpty 
} from "../Helpers/FormatHelper";

function CustomDatePicker({ component_config, handle_change }) {
  component_config = component_config == null ? { } : component_config;
  
  const startDateParamName = IsEmpty(component_config.start_date_param_name) 
      ? "from" 
      : component_config.start_date_param_name;
  const endDateParamName = IsEmpty(component_config.end_date_param_name) 
      ? "to" 
      : component_config.end_date_param_name;
  
  const handleDateChange = (event, picker) => {
    const start = picker.startDate 
        ? moment(picker.startDate._d).format("MM/DD/YYYY hh:mm A") 
        : null;
    handle_change({ 
        [startDateParamName]: start
    });
  }

  const handleDateRangeChange = (event, picker) => {
    const start = picker.startDate 
        ? moment(picker.startDate._d).format("YYYY-MM-DD") 
        : null;
    const end = picker 
        ? moment(picker.endDate._d).format("YYYY-MM-DD") 
        : null;
    handle_change({ 
        [startDateParamName]: start,
        [endDateParamName]: end
    });
  };

  return (
    <>
      { !component_config.date_range &&
          <DateRangePicker 
              initialSettings={{
                singleDatePicker: true
              }} 
              onApply={ handleDateChange }>
            <input type="text" className="form-control w-50" />
          </DateRangePicker>
      }
      { component_config.date_range &&
          <DateRangePicker onApply={ handleDateRangeChange } autoUpdateInput={false}>
            <input type="text" 
                className="form-control w-100" 
                title="Request Accepted Date"
                placeholder="Request Accepted Date"
                />
          </DateRangePicker>
      }
    </>
  );
}

export default CustomDatePicker;