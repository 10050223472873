const kpiDimensions = "/KPI/GetKPIDimensions";
const fanosProgramList = "/EHPMD_WebApi/ProgramMode";
const fanosEnvironmentList =
  "/POD_WebApi/EnvironmentList?EnvironmentGroupCode=hub";
const fanosCommodityTypeList = "/EHPMD_WebApi/CommodityTypes";
const fanosModeList = "/MO_WebApi/ModeList";
const daguRegionList = "/Lookup/GetAdministrativeUnits/4089";
const daguZoneList = "/Lookup/GetAdministrativeUnits";
const daguFacilityList = "/Lookup/GetInstitutions/0/0";

export const ENVIRONMENT_SELECT_CONFIG = {
  api_url: kpiDimensions,
  data_source: "kpi",
  options_data_property: "locations",
  label_property: "name",
  value_property: "id",
  place_holder: "Hub",
  param_name: "environmentId",
};

export const PROGRAM_SELECT_CONFIG = {
  api_url: fanosProgramList + "?IsFilter=true",
  options_data_property: "Data",
  label_property: "Program",
  value_property: "ProgramID",
  place_holder: "Program",
  param_name: "programId",
};

export const MODE_SELECT_CONFIG = {
  api_url: kpiDimensions,
  data_source: "kpi",
  options_data_property: "modes",
  label_property: "name",
  value_property: "id",
  place_holder: "Mode",
  param_name: "modeId",
};

export const FN_COMMODITY_TYPE_SELECT_CONFIG = {
  api_url: fanosCommodityTypeList,
  options_data_property: "Data",
  label_property: "CommodityType",
  value_property: "CommodityTypeCode",
  place_holder: "Commodity Type",
  param_name: "CommodityTypeCode",
};

export const FN_PROGRAM_SELECT_CONFIG = {
  api_url: fanosProgramList,
  options_data_property: "Data",
  label_property: "Program",
  value_property: "ProgramCode",
  place_holder: "Program",
  param_name: "ProgramCode",
};

export const FN_ENVIRONMENT_SELECT_CONFIG = {
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentCode",
  place_holder: "Hub",
  param_name: "EnvironmentCode",
};

export const FN_MODE_SELECT_CONFIG = {
  api_url: fanosModeList,
  options_data_property: "Data",
  label_property: "Mode",
  value_property: "ModeCode",
  place_holder: "Mode",
  param_name: "ModeCode",
};

export const FN_COMMODITY_TYPE_ID_SELECT_CONFIG = {
  api_url: fanosCommodityTypeList,
  options_data_property: "Data",
  label_property: "CommodityType",
  value_property: "CommodityTypeID",
  place_holder: "Commodity Type",
  param_name: "CommodityTypeID",
};

export const DG_COMMODITY_TYPE_ID_SELECT_CONFIG = {
  api_url: fanosCommodityTypeList,
  options_data_property: "Data",
  label_property: "CommodityType",
  value_property: "SN",
  place_holder: "Commodity Type",
  param_name: "commodityTypeSn",
};

export const FN_PROGRAM_ID_SELECT_CONFIG = {
  api_url: fanosProgramList,
  options_data_property: "Data",
  label_property: "Program",
  value_property: "ProgramID",
  place_holder: "Program",
  param_name: "ProgramID",
  parent_filter: {
    name: "Mode",
    param_name: "ModeID",
    value_property: "ModeID",
  },
};

export const FN_ENVIRONMENT_ID_SELECT_CONFIG = {
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentID",
  place_holder: "Hub",
  param_name: "EnvironmentID"
};

export const FN_SS_ENVIRONMENT_ID_SELECT_CONFIG = {
  api_url: fanosEnvironmentList,
  options_data_property: "Data",
  label_property: "Environment",
  value_property: "EnvironmentID",
  place_holder: "Hub",
  param_name: "EnvironmentID",
  // default_option: {
  //   label: "Home Office",
  //   value: 4
  // }
};

export const FN_MODE_ID_SELECT_CONFIG = {
  api_url: fanosModeList,
  options_data_property: "Data",
  label_property: "Mode",
  value_property: "ModeID",
  place_holder: "Mode",
  param_name: "ModeID",
};

export const DG_MODE_SELECT_CONFIG = {
  api_url: fanosModeList,
  options_data_property: "Data",
  label_property: "Mode",
  value_property: "ModeCode",
  place_holder: "Mode",
  param_name: "accountCode",
};

export const DG_MODE_WITH_SN_SELECT_CONFIG = {
  api_url: fanosModeList,
  options_data_property: "Data",
  label_property: "Mode",
  value_property: "ModeSN",
  place_holder: "Mode",
  param_name: "modeSn",
};

export const DG_REGION_ID_SELECT_CONFIG = {
  api_url: daguRegionList,
  data_source: "dagu",
  options_data_property: "model",
  label_property: "name",
  value_property: "id",
  place_holder: "Region",
  param_name: "regionId",
  hidden_options: ["99", "Federal Government of Ethiopia", "Unknown(R)"],
  check_permission: true,
  is_clearable: true
};

export const DG_ZONE_ID_SELECT_CONFIG = {
  option_type: "hardCode",
  options: [],
  label_property: "name",
  value_property: "id",
  place_holder: "Zone",
  param_name: "zoneId",
  //is_clearable: true,
  // parent_filter: {
  //   name: "region",
  //   param_name: "regionId",
  //   value_property: "parentId",
  // },
};

export const DG_WOREDA_ID_SELECT_CONFIG = {
  option_type: "hardCode",
  options: [],
  label_property: "name",
  value_property: "id",
  place_holder: "Woreda",
  param_name: "woredaId",
  //is_clearable: true,
  // parent_filter: {
  //   name: "zone",
  //   param_name: "zoneId",
  //   value_property: "parentId",
  // },
};

export const DG_KPI_REGION_ID_SELECT_CONFIG = {
  api_url: daguRegionList,
  data_source: "dagu",
  options_data_property: "model",
  label_property: "name",
  value_property: "sn",
  place_holder: "Region",
  param_name: "regionSn",
  hidden_options: ["Federal", "99", "Federal Government of Ethiopia", "Unknown(R)"],
  check_permission: true,
  is_clearable: false
};

export const DG_FACILITY_ID_SELECT_CONFIG = {
  api_url: daguFacilityList,
  data_source: "dagu",
  options_data_property: "model",
  label_property: "name",
  value_property: "id",
  place_holder: "Facility",
  param_name: "institutionId",
  parent_filter: {
    name: "region",
    param_name: "regionId",
    value_property: "regionId",
  },
  picked_list_column: "sn",
  xpicked_list: [
    148052, 215201, 147313, 153794, 155829, 154686, 153045, 
    161252, 161076, 157211, 156818, 158570, 156302, 175068
  ],
};

export const FACILITY_CODE_SELECT_CONFIG = {
  option_type: "hardCode",
  options: [
    {value: "UN Agency", label: "UN Agency"},
    {value: "Retailer", label: "Retailer"},
    {value: "Research institutions", label: "Research institutions"},
    {value: "NGO", label: "NGO"},
    {value: "Healthcare facilities", label: "Healthcare facilities"},
    {value: "Government Institution", label: "Government Institution"},
    {value: "Volunteers for campaign", label: "Volunteers for campaign"},
    {value: "Universities", label: "Universities"},
    {value: "Exporter", label: "Exporter"},
    {value: "Wholesaler", label: "Wholesaler"},
    {value: "Importer", label: "Importer"},
    {value: "Manufacturer", label: "Manufacturer"},
  ],
  place_holder: "Facility",
  param_name: "FacilityType",
};

export const PO_TYPE_SELECT_CONFIG = {
  option_type: "hardCode",
  options: [
    {value: "Donation", label: "Donation"},
    {value: "Standard", label: "Standard"}
  ],
  place_holder: "PO Type",
  param_name: "PurchaseOrderType",
};


const SELECT_CONSTANTS = [PROGRAM_SELECT_CONFIG, MODE_SELECT_CONFIG];

export default SELECT_CONSTANTS;
